export const state = () => ({
  activity_logs: {},

  activity_log: null,
  loading: false
})

export const mutations = {
  ACTIVATE_LOGS (state, payload) {
    state.activity_logs = payload
  },
  SET_ACTIVATE_LOGS (state, payload) {
    state.activity_log = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/activity-logs', {
        params: {
          order_id: payload.order_id,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[event]': payload.event,
          'filter[restored]': payload.restored,
          'filter[created_at]': payload.date_range,
          'filter[subject_id]': payload.subject_id,
          'filter[causer_id]': payload.causer_id,
          'filter[causer_type]': payload.causer_type,
          'filter[subject_type]': payload.subject_type,
          'filter[causer_name]': payload.causer_name
        }
      })
      .then((response) => {
        commit('ACTIVATE_LOGS', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async get_activity_log ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$get(`/activity-logs/${payload}`).then((res) => {
      commit('SETLOADING', false)
      commit('SET_ACTIVATE_LOGS', res.data)
    })
  }
}

export const state = () => ({
  statistics: {},
  comments: {},
  ratesList: [],
  responseMessage: {},
  loading: false
})

export const mutations = {
  COMMENTS (state, payload) {
    state.comments = payload
  },
  ADDREPLY (state, payload) {
    state.comments.comments[payload.reply_index].replyComments.push(
      payload.reply_text
    )
  },
  RESPONSEMESSAGE (state, payload) {
    state.responseMessage = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  GETRATESLIST (state, payload) {
    state.ratesList = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/rates', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[writerable_id]': payload.writerable_id,
          'filter[rateable]': payload.rateable ? payload.rateable : undefined,
          'filter[status]': payload.status ? payload.status : undefined

        }
      })
      .then((response) => {
        response.data.rates.forEach((rates) => {
          if (rates.reply !== null) {
            rates.newComment = true
          } else {
            rates.newComment = false
          }
          rates.textComment = ''
          rates.hide = false
          rates.errors = {
            text_comment: { show: false, message: 'التعليق مطلوب' }
          }
          commit('SETLOADING', false)
        })
        commit('COMMENTS', response.data)
      })
      .catch((e) => {
        commit('SETLOADING', false)
        console.log(e)
      })
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/rates').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async UpdateRates ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post(
      `rates/${payload.id}`,
      payload.form_data
    )

    // commit('RESPONSEMESSAGE', payload)
    return response
  },

  async addComment ({ commit, store }, payload) {
    const response = await this.$axios.$post('/comments', payload.form_data)
    return response
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/rates', payload)
      commit('SETLOADING', false)
      commit('COMMENTS', response.data)
      return response
    }
  },

  async fetchList ({ commit }) {
    return await this.$axios
      .$get('/rates', {
        params: {
          list: true
          // per_page: 1000
        }
      })
      .then((response) => {
        commit('GETRATESLIST', response.data.rates)
      })
  },

  bulkChanges ({ commit }, payload) {
    return this.$axios
      .$post('/rates', payload)
  }
}

export const state = () => ({
  courses: {},
  course: {},
  statistics: null,
  loading: false
})

export const mutations = {
  SETCOURSES (state, payload) {
    state.courses = payload
  },
  SETCOURSE (state, payload) {
    state.course = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  ADDTAG (state, payload) {
    state.course.tag_names.push(payload)
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  EMPTYSPICALLINK (state) {
    state.course.special_link = ''
  }
}
export const actions = {
  async fetch ({ state, commit, dispatch }, payload) {
    if (state.statistics === null) {
      dispatch('getStatistics')
    }
    if (payload !== null && payload !== undefined) {
      if (payload.page === 1) {
        commit('SETLOADING', true)
      }
      const response = await this.$axios.$get('/courses', {
        params: {
          search_text: payload.search_text,
          sort: payload.sort,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          'filter[is_purchasable]': payload.is_purchasable,
          'filter[active]': payload.active,
          'filter[category]': payload.category,
          'filter[trashed]': payload.trashed
        }
      })
      commit('SETLOADING', false)
      commit('SETCOURSES', response.data)
      return response
    } else {
      commit('SETLOADING', true)
      const response = await this.$axios.$get('/courses', {
        params: {
          search_text: '',
          page: 1,
          per_page: 10,
          orderBy: 'created_at'
        }
      })
      commit('SETLOADING', false)
      commit('SETCOURSES', response.data)
      return response
    }
  },

  async getSpecialLink ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios
      .$post(`/courses/generate-special-id/${payload}`)
      .then((response) => {
        commit('SETLOADING', false)
        commit('SETCOURSE', response.data)
      })
    return response
  },
  async getCourse ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$get(`/courses/${payload}`).then((response) => {
      commit('SETLOADING', false)
      commit('SETCOURSE', response.data)
    })
  },
  addCourse ({ commit }, payload) {
    return this.$axios.$post('/courses', payload)
  },
  editCourse ({ commit }, payload) {
    return this.$axios.$post(`/courses/${payload.id}`, payload.form_data)
  },
  async deleteCourse ({ commit }, payload) {
    const response = await this.$axios.$delete('/courses', {
      data: {
        ids: payload
      }
    })
    return response
  },
  async coursesDuplicate ({ commit }, payload) {
    const response = await this.$axios.$post('/courses/duplicate', payload)
    return response
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/courses', payload)
      commit('SETLOADING', false)
      commit('SETCOURSES', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/courses').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async searchForCourse ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$get('/courses', {
      params: {
        search_text: payload.search_text,
        per_page: payload.per_page,
        orderBy: payload.orderBy
      }
    })
    commit('SETLOADING', false)
    commit('SETCOURSES', response.data)
    return response
  },
  bulkChanges ({ commit }, payload) {
    return this.$axios.$post('/courses', payload)
  },
  confirmDeletion ({ commit }, payload) {
    return this.$axios.$delete('/courses', {
      data: {
        ids: payload,
        confirm_deletion: true
      }
    })
  }
}

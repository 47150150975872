export const state = () => ({
  profile: {},
  countries: {}
})

// getter: {
// fetch_avatar(state) {
//   return state.profile.avatar
// }
// }

export const mutations = {
  SET_PROFILE (state, payload) {
    state.profile = payload
  },
  COUNTRIES (state, payload) {
    state.countries = payload
  }
}

export const actions = {
  fetch ({ commit }, payload) {
    return this.$axios
      .$get('/profile')
      .then((response) => {
        commit('SET_PROFILE', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  fetchCountries ({ commit }, payload) {
    return this.$axios
      .$get('/countries', {
        params: {
          list: payload.list
        }
      })
      .then((response) => {
        commit('COUNTRIES', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  async updateProfile ({ commit }, payload) {
    const response = await this.$axios.$post('/profile/', payload.form_data)
    commit('SET_PROFILE', payload)
    return response
  }
}

export const state = () => ({
  allHistory: [],
  countries: [],
  devices: [],
  operatingSystems: [],
  statistics: null,
  loading: false
})

export const mutations = {
  LOADOHISTORY (state, payload) {
    state.allHistory = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  SETCOUNTRIES (state, payload) {
    state.countries = payload.countries
  },
  SETDEVICES (state, payload) {
    state.devices = payload.devices
  },
  SETOPERATINGSYSTEMS (state, payload) {
    state.operatingSystems = payload.operating_system
  }
}
export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get('/login-histories', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[sign_in_date]':
              payload.sign_in_date &&
              payload.sign_in_date.length > 0 &&
              payload.sign_in_date[0] !== null
                ? `${payload.sign_in_date[0]}, ${payload.sign_in_date[1]}`
                : null,
            'filter[device]': payload.device ? payload.device : null,
            'filter[operating_system]': payload.operating_system
              ? payload.operating_system
              : null,
            'filter[country_name]': payload.country_name
              ? payload.country_name
              : null,
            'filter[customer_id]': payload.customer_id
              ? payload.customer_id
              : null
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('LOADOHISTORY', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/login-histories', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADOHISTORY', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },

  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/login-histories').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },

  async getDevices ({ commit }) {
    await this.$axios.$get('/login-histories/devices').then((response) => {
      commit('SETDEVICES', response.data)
    })
  },
  async getCountries ({ commit }) {
    await this.$axios.$get('/login-histories/countries').then((response) => {
      commit('SETCOUNTRIES', response.data)
    })
  },
  async getOperatingSystems ({ commit }) {
    await this.$axios
      .$get('/login-histories/operating-systems')
      .then((response) => {
        commit('SETOPERATINGSYSTEMS', response.data)
      })
  },
  async updateReview ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('/login-histories', payload)
    commit('SETLOADING', false)
    return response
  }
}

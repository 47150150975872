export const state = () => ({
  orders: [],
  selectedItems: null,
  statistics: {},
  bankAccounts: [],
  bankTransferSource: [],
  order: null,
  loading: false
})
export const mutations = {
  LOADORDERS (state, payload) {
    state.orders = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETORDER (state, payload) {
    state.order = payload
  },
  SETSELECTEDORDERITEMS (state, payload) {
    state.selectedItems = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  BANKACOUNTS (state, payload) {
    state.bankAccounts = payload
  },
  TRANSFERSOURSES (state, payload) {
    state.bankTransferSource = payload
  }
}
export const actions = {
  async fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get('/orders', {
          params: {
            search_text: payload.search_text ? payload.search_text : null,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[trashed]': payload.trashed,
            'filter[customer_id]': payload.customer_id ? payload.customer_id : null,
            'filter[status]': payload.status,
            'filter[payment_method]': payload.payment_method,
            'filter[date_range]': payload.date_range,
            'filter[nodhom_status]': payload.nodhom_status,
            'filter[subscriptions_transferred]': payload.subscriptions_transferred
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('LOADORDERS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/orders', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADORDERS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async createOrder ({ commit }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post('/orders', payload)
    commit('SETLOADING', false)
    commit('SETSELECTEDORDERITEMS', response.data)
    return response
  },
  getBankAccounts ({ commit }, payload) {
    return this.$axios
      .$get('orders/bank-accounts')
      .then((response) => {
        commit('BANKACOUNTS', response.data.bank_accounts)
        commit('TRANSFERSOURSES', response.data.bank_transfer_source)
        return response
      })
      .catch((err) => {
        return err.response.data
      })
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/orders', payload)
      commit('SETLOADING', false)
      commit('LOADORDERS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/orders').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async getOrder ({ commit }, payload) {
    commit('SETLOADING', true)

    return await this.$axios.$get(`/orders/${payload}`).then((res) => {
      commit('SETLOADING', false)
      commit('SETORDER', res.data)
    })
  },
  async editOrder ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post(
      `/orders/${payload.id}`,
      payload.form_data
    )
    await dispatch('getOrder', payload.id)
    await dispatch('fetch')
    commit('SETLOADING', false)

    return response
  },
  export ({ commit, dispatch }, payload) {
    return this.$axios
      .$post('/orders-export', payload, {
        params: {
          locale: payload.locale
        },
        responseType: 'blob',
        headers: {
          Authorization: this.$axios.defaults.headers.common.Authorization
        }
      })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'Orders.xlsx')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
  },
  async addNote ({ _ }, payload) {
    return await this.$axios.$post('/order-notes', payload)
  },
  async editNote ({ _ }, payload) {
    return await this.$axios.$post(
      `/order-notes/${payload.note_id}`,
      payload.form_data
    )
  },
  async sendNote ({ _ }, payload) {
    return await this.$axios.$post('/notes-customer', payload)
  },
  async deleteNote ({ _ }, payload) {
    return await this.$axios.$post('/order-notes', payload)
  },
  deleteOrder ({ commit }, payload) {
    return this.$axios.$delete('/orders', {
      data: {
        ids: payload
      }
    })
  },
  async removeProductOrder ({ commit }, payload) {
    return await this.$axios.$post(
      `/orders/${payload.id}/remove-item`,
      payload.form_data
    )
  },
  bulkChanges ({ commit }, payload) {
    return this.$axios.$post('/orders', payload)
  },
  checkOrder ({ commit }, payload) {
    return this.$axios.$post('/orders/callback/', payload)
  },
  downloadInvoice ({ commit }, payload) {
    return this.$axios({
      url: `/orders/${payload}/download-invoice`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      const fileURL = window.URL.createObjectURL(blob)
      const fileLink = document.createElement('a')
      const filename = response.headers.filename
      fileLink.href = fileURL
      fileLink.setAttribute('download', filename)
      document.body.appendChild(fileLink)

      fileLink.click()
    })
  },

  async downloadAttachment ({ commit }, payload) {
    return await this.$axios.$post('/download', payload, {
      responseType: 'blob'
    })
  },
  async subscriptionTransfer ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('/orders/transfer-subscriptions/', payload)
    return response
  }
}

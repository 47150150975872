export const state = () => ({
  customersPoints: [],
  pointsSettings: [],
  AvailablePoints: [],
  statistics: null,
  loading: false
})

export const mutations = {
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETPOINTSSETTINGS (state, payload) {
    state.pointsSettings = payload
  },
  SETCUSTOMERSPOINTS (state, payload) {
    state.customersPoints = payload
  },
  SETAVAILABLEPOINTS (state, payload) {
    state.AvailablePoints = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async fetchPointsSettings ({ commit, router }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get('/settings/points', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('SETPOINTSSETTINGS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/settings/points', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('SETPOINTSSETTINGS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  // FOR ALL CUSTOMERS

  async fetchCustoemrsPoints ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get('/points-records', {
          params: {
            search_text: payload.search_text ? payload.search_text : null,
            search_email: payload.search_email ? payload.search_email : null,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[date_range]': payload.date_range,
            'filter[point_id]': payload.pointId ? payload.pointId : null

          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('SETCUSTOMERSPOINTS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/points-records', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('SETCUSTOMERSPOINTS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  // FOR SINGLE CUSTOMER
  async fetchCustoemrPoints ({ commit }, payload) {
    if (payload && payload.page) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get(`customers/${payload.customerId}/points-records`, {
          params: {
            search_text: payload.search_text ? payload.search_text : null,
            search_email: payload.search_email ? payload.search_email : null,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[date_range]': payload.date_range,
            'filter[point_id]': payload.pointId ? payload.pointId : null

          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('SETCUSTOMERSPOINTS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get(`customers/${payload.customerId}/points-records`, {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('SETCUSTOMERSPOINTS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },

  async getStatistics ({ commit }) {
    commit('SETLOADING', true)

    await this.$axios
      .$get('/statistics/points-records')
      .then((response) => {
        commit('SETSTATISTICS', response.data)
        commit('SETLOADING', false)
      })
  },

  async getAvailablePoints ({ commit, dispatch }, payload) {
    const response = await this.$axios.$get('/points-records/available-points', payload)
    commit('SETAVAILABLEPOINTS', response.data)

    return response
  },
  async create ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post('/points-records', payload)

    dispatch('fetchCustoemrsPoints')
    return response
  },
  async update ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post(
      `/settings/points/${payload.id}`,
      payload.form_data
    )
    dispatch('fetchPointsSettings')
    return response
  }
}

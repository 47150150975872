export const state = () => ({
  users: [],
  user: null,
  loading: false,
  statistics: {},
  countryiesList: [],
  rolesList: []
})
export const mutations = {
  SETUSERS (state, payload) {
    state.users = payload
  },
  ADDUSER (state, payload) {
    state.users = payload
  },
  EDITUSER (state, payload) {
    state.users.users[payload.index] = payload.user
  },
  DELETEUSER (state, userIndex) {
    state.users.users.splice(userIndex, 1)
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETUSER (state, payload) {
    state.user = payload
  },
  ADDTAG (state, payload) {
    state.user.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.user.tag_names.splice(payload, 1)
  },
  SETCOUNTRIES (state, payload) {
    state.countryiesList = payload.countries
  },
  SETROLES (state, payload) {
    state.rolesList = payload.roles
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }

}
export const actions = {
  fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return this.$axios
        .$get('/users', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[role]': payload.role,
            'filter[trashed]': payload.trashed

          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('SETUSERS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/users', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('SETUSERS', response.data)
        }).catch(() => {
          commit('SETLOADING', false)
        })
    }
  },

  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/users', payload)
      commit('SETLOADING', false)
      commit('SETUSERS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/users').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },

  async getUser ({ commit }, payload) {
    return await this.$axios.$get(`/users/${payload}`).then((res) => {
      commit('SETUSER', res.data)
    })
  },
  addUser ({ commit, dispatch }, payload) {
    const response = this.$axios.$post('/users', payload)
    dispatch('fetch')
    return response
  },
  editUser ({ commit }, payload) {
    return this.$axios
      .$post(`/users/${payload.id}`, payload.form_data)
  },
  deleteUser ({ commit }, payload) {
    return this.$axios
      .$delete('/users', {
        data: {
          ids: [payload]
        }
      })
    // commit('DELETEUSER', userIndex)
  },
  changePassword ({ commit }, payload) {
    return this.$axios
      .$post(`/update-user-password/${payload.id}`, payload.form_data)
  },
  async getCountries ({ commit }) {
    return await this.$axios.$get('/countries', {
      params: {
        list: true
      }
    }).then((res) => {
      commit('SETCOUNTRIES', res.data)
    })
  },
  async getRoles ({ commit }) {
    return await this.$axios.$get('/roles', {
      params: {
        list: true
      }
    }).then((res) => {
      commit('SETROLES', res.data)
    })
  }
}

export const state = () => ({
  carts: {},
  cartItem: {},
  loading: false
})

export const mutations = {
  SETCARTS (state, payload) {
    state.carts = payload
  },
  SETCARTITEM (state, payload) {
    state.cartItem = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}
export const actions = {
  async fetch ({ state, commit, dispatch }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$get('/carts', {
        params: {
          search_text: payload.search_text,
          sort: payload.sort,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          'filter[carts_count]': payload.carts_count
        }
      })
      commit('SETLOADING', false)
      commit('SETCARTS', response.data)
      return response
    } else {
      commit('SETLOADING', true)
      const response = await this.$axios.$get('/carts', {
        params: {
          search_text: '',
          page: 1,
          per_page: 10,
          orderBy: 'created_at'
        }
      })
      commit('SETLOADING', false)
      commit('SETCARTS', response.data)
      return response
    }
  },
  async getProductCarts ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$get(`${payload.type}/${payload.id}/carts`, {
      params: {
        search_text: payload.search_text ? payload.search_text : '',
        sort: payload.sort ? payload.sort : null,
        page: payload.page ? payload.page : 1,
        per_page: payload.per_page ? payload.per_page : 10,
        orderBy: payload.orderBy ? payload.orderBy : 'created_at'
        // 'filter[active]': payload.active,
        // 'filter[category]': payload.category,
        // 'filter[trashed]': payload.trashed

      }
    })
    commit('SETLOADING', false)
    commit('SETCARTS', response.data)
    return response

    // else {
    //   commit('SETLOADING', true)
    //   const response = await this.$axios.$get(`${payload.type}/${payload.id}/carts`, {
    //     params: {
    //       search_text: '',
    //       page: 1,
    //       per_page: 10,
    //       orderBy: 'created_at'
    //     }
    //   })
    //   commit('SETLOADING', false)
    //   commit('SETCARTS', response.data)
    //   return response
    // }
  },

  async getCartItem ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$get(`/courses/${payload}`).then((response) => {
      commit('SETLOADING', false)
      commit('SETCOURSE', response.data)
    })
  }

}

export const state = () => ({
  group_requests: {},
  group_request: null,
  loading: false
})

export const mutations = {
  GROUPS_REQUESTS (state, payload) {
    state.group_requests = payload
  },
  SET_GROUP_REQUEST (state, payload) {
    state.group_request = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/groups-application-requests', {
        params: {
          per_page: payload.per_page,
          page: payload.page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[status]': payload.status,
          'filter[type]': payload.type,
          'filter[date_range]': payload.date_range,
          'filter[trashed]': payload.trashed
        }
      })
      .then((response) => {
        commit('GROUPS_REQUESTS', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async get_grouped_request ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get(`/groups-application-requests/${payload}`)
      .then((res) => {
        commit('SETLOADING', false)
        commit('SET_GROUP_REQUEST', res.data)
      })
  },
  async delete_grouped_request ({ dispatch, commit }, payload) {
    return await this.$axios.$delete('/groups-application-requests', {
      data: {
        ids: payload
      }
    })
  },
  async edit_grouped_request ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post(
      `/groups-application-requests/${payload.id}`,
      payload.form_data
    )
    await dispatch('get_grouped_request', payload.id)
    commit('SETLOADING', false)

    return response
  },
  async update_grouped_request_status ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post(
      '/groups-application-requests/', payload)
    return response
  },
  async restore_grouped_request ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post(
      '/groups-application-requests/', payload)
    return response
  },
  // eslint-disable-next-line no-empty-pattern
  async addNote ({}, payload) {
    return await this.$axios.$post('/order-notes', payload)
  },
  async downloadِAttachments ({ commit }, payload) {
    return await this.$axios({
      url: `/groups-application-requests/download-attachment/${payload}`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      let removeText = ''
      let fullPath = ''
      const description = response.headers
      for (const key in description) {
        if (key === 'content-disposition') {
          removeText = description[key].replace('attachment; filename="', '')
          fullPath = removeText.replace('"', '')
        }
      }
      const blob = new Blob([response.data], {
        type: 'application/octet-stream'
      })
      const fileURL = window.URL.createObjectURL(blob)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fullPath)
      document.body.appendChild(fileLink)

      fileLink.click()
    })
  }
}

export const state = () => ({
  month_wallpapers: {},
  wallpapers: {},
  // wallpapers: null,
  loading: false
})

export const mutations = {
  MONTH_WALLPAPERS (state, payload) {
    state.month_wallpapers = payload
  },
  SET_MONTH_WALLPAPER (state, payload) {
    state.wallpapers = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/month-wallpapers', {
        params: {
          per_page: payload.per_page,
          page: payload.page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[type]': payload.type,
          'filter[date_range]': payload.date_range,
          'filter[trashed]': payload.trashed
        }
      })
      .then((response) => {
        commit('MONTH_WALLPAPERS', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async addMonthWallpapers ({ commit }, payload) {
    return await this.$axios.$post('/month-wallpapers', payload)
  },
  async getMonthWallpapers ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get(`/month-wallpapers/${payload}`)
      .then((response) => {
        commit('SET_MONTH_WALLPAPER', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async setAsMonthWallpaper ({ commit }, payload) {
    return await this.$axios.$post(`/month-wallpapers/${payload.ids}`, payload.form_data)
  },
  async updateDisplayedWallpaper ({ commit }, payload) {
    return await this.$axios.$post('/month-wallpapers', payload)
  },
  async deleteMonthWallpapers ({ commit, dispatch }, payload) {
    const response = await this.$axios.$delete('/month-wallpapers', payload
      // params: {
      //   ids: payload.ids
      // }
    )
    return response
  }

}

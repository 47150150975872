export const state = () => ({
  suspiciousHistory: [],
  statistics: null,
  loading: false
})

export const mutations = {
  LOADOSUSPICIOUSHISTORY (state, payload) {
    state.suspiciousHistory = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get('/suspicious-logins', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[sign_in_date]': payload.sign_in_date,
            'filter[device]': payload.device,
            'filter[operating_system]': payload.operating_system,
            'filter[country_name]': payload.country_name,
            'filter[period]': payload.period
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('LOADOSUSPICIOUSHISTORY', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/suspicious-logins', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADOSUSPICIOUSHISTORY', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async getStatistics ({ commit }) {
    commit('SETLOADING', true)

    await this.$axios.$get('/statistics/suspicious-logins').then((response) => {
      commit('SETSTATISTICS', response.data)
      commit('SETLOADING', false)
    })
  },
  async updateReview ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('/suspicious-logins', payload)
    commit('SETLOADING', false)
    return response
  }
}

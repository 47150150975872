export const state = () => ({
  monthly_notebook: {},
  note_book: {},
  loading: false
})

export const mutations = {
  MONTHLY_NOTEBOOK (state, payload) {
    state.monthly_notebook = payload
  },
  SET_MONTHLY_NOTEBOOK (state, payload) {
    state.note_book = payload
  },

  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/month-notes', {
        params: {
          per_page: payload.per_page,
          page: payload.page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[displayed]': payload.displayed,
          'filter[selected]': payload.selected,
          'filter[date_range]': payload.date_range,
          'filter[trashed]': payload.trashed
        }
      })
      .then((response) => {
        commit('MONTHLY_NOTEBOOK', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async addMonthlyNotebook ({ commit }, payload) {
    return await this.$axios.$post('/month-notes', payload)
  },
  async updateMonthlyNotebook ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post(`/month-notes/${payload.id}`, payload.form_data)
    commit('SETLOADING', false)
    return response
  },
  async deleteMonthlyNotebook ({ commit, dispatch }, payload) {
    const response = await this.$axios.$delete('/month-notes', {
      params: {
        ids: [payload]
      }
    })
    return response
  },
  async downloadMonthlyNotebook ({ commit, context }, payload) {
    return await this.$axios({
      url: `/month-notes/download/${payload}`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      let removeText = ''
      // take the name of file from the header
      let fullPath = ''
      const description = response.headers
      for (const key in description) {
        if (key === 'content-disposition') {
          removeText = description[key].replace('attachment; filename="', '')
          fullPath = removeText.replace('"', '')
        }
      }
      const blob = new Blob([response.data], {
        type: 'application/octet-stream'
      })
      const fileURL = window.URL.createObjectURL(blob)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fullPath)
      document.body.appendChild(fileLink)

      fileLink.click()
    })
      .catch((err) => {
        console.log(err)
      })
  }
}

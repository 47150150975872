export const state = () => ({
  statistics: {},
  comments: {},
  commentsList: [],
  responseMessage: {},
  loading: false
})

export const mutations = {
  COMMENTS (state, payload) {
    state.comments = payload
  },
  ADDREPLY (state, payload) {
    state.comments.comments[payload.reply_index].replyComments.push(
      payload.reply_text
    )
  },
  RESPONSEMESSAGE (state, payload) {
    state.responseMessage = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  ADDCOMMENTSLIST (state, payload) {
    state.commentsList = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)

    return await this.$axios
      .$get('/comments', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[writerable_id]': payload.writerable_id,
          'filter[status]': payload.status,
          'filter[trashed]': payload.trashed,
          'filter[commentable]': payload.commentable_id
        }
      })
      .then((response) => {
        response.data.comments.forEach((comments) => {
          if (comments.reply !== null) {
            comments.newComment = true
          } else {
            comments.newComment = false
          }
          comments.textComment = ''
          comments.hide = false
          comments.errors = {
            text_comment: { show: false, message: 'التعليق مطلوب' }
          }
        })
        commit('SETLOADING', false)
        commit('COMMENTS', response.data)
      })
      .catch((e) => {
        commit('SETLOADING', false)

        console.log(e)
      })
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/comments', payload)
      commit('SETLOADING', false)
      commit('COMMENTS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/comments').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async UpdateComment ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post(
      `comments/${payload.id}`,
      payload.form_data
    )

    // commit('RESPONSEMESSAGE', payload)
    return response
  },
  async applyProcedure ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('comments', payload.form_data)

    // commit('RESPONSEMESSAGE', payload)
    return response
  },
  async addComment ({ commit, store }, payload) {
    const response = await this.$axios.$post('/comments', payload.form_data)
    return response
  },
  async fetchList ({ commit }) {
    return await this.$axios
      .$get('/comments/commentables-list')
      .then((response) => {
        commit('ADDCOMMENTSLIST', response.data)
      })
  },
  bulkChanges ({ commit }, payload) {
    return this.$axios.$post('/comments', payload)
  }
}

export const state = () => ({
  books: {},
  statistics: {},
  book: {},
  loading: false
})

export const mutations = {
  SETBOOKS (state, payload) {
    state.books = payload
  },
  SETBOOK (state, payload) {
    state.book = payload
  },
  ADDTAG (state, payload) {
    state.book.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.book.tag_names.splice(payload, 1)
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      const response = await this.$axios.$get(
        '/publications?filter[type]=books',
        {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[can_comment]': payload.is_purchasable,
            'filter[active]': payload.active,
            'filter[trashed]': payload.trashed
          }
        }
      )
      commit('SETBOOKS', response.data)
      return response
    } else {
      const response = await this.$axios.$get(
        '/publications?filter[type]=books',
        {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        }
      )
      commit('SETBOOKS', response.data)
      return response
    }
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post(
        '/publications?type=books',
        payload
      )
      commit('SETLOADING', false)
      commit('SETBOOKS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios
      .$get('/statistics/publications?type=books')
      .then((response) => {
        commit('SETSTATISTICS', response.data)
      })
  },
  async getBook ({ commit }, payload) {
    return await this.$axios
      .$get(`/publications/books/${payload}`)
      .then((response) => {
        commit('SETBOOK', response.data)
      })
  },
  addBook ({ commit }, payload) {
    return this.$axios.$post('/publications/books', payload)
  },
  editBook ({ commit }, payload) {
    return this.$axios.$post(
      `/publications/books/${payload.id}`,
      payload.form_data
    )
  },
  async deleteBook ({ commit }, payload) {
    const response = await this.$axios.$delete('/publications/books', {
      data: {
        ids: [payload]
      }
    })
    return response
  }
}

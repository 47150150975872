export const state = () => ({
  customers: [],
  customersList: [],
  customerMessages: [],
  statistics: {},
  customer: null,
  loading: false,
  countryiesList: [],
  levelList: [],
  checkData: null

})
export const mutations = {
  LOADCUSTOMERS (state, payload) {
    state.customers = payload
  },
  LOADCUSTOMERSLIST (state, payload) {
    state.customersList = payload
  },
  ADDCUSTOMER (state, payload) {
    state.customers.push({
      ...payload,
      customer_image: '',
      views: '0',
      shares: '0',
      comments: '0'
    })
  },
  EDITCUSTOMER (state, payload) {
    state.customers.customers[payload.index] = payload.customer
  },
  DELETECUSTOMER (state, customerIndex) {
    state.customers.customers.splice(customerIndex, 1)
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETCUSTOMER (state, payload) {
    state.customer = payload
  },
  ADDTAG (state, payload) {
    state.customer.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.customer.tag_names.splice(payload, 1)
  },
  SETCOUNTRIES (state, payload) {
    state.countryiesList = payload.countries
  },
  SETLEVEL (state, payload) {
    state.levelList = payload.customer_levels
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  SETMARKETERPRODUCTS (state, payload) {
    state.marketerProducts = payload
  },
  SETMARKETERSALES (state, payload) {
    state.marketerSales = payload
  },
  SETCHECKDATA (state, payload) {
    state.checkData = payload
  },
  RESETCHECKDATA (state) {
    state.checkData = null
  },
  SETCUSTOMERMESSAGES (state, payload) {
    state.customerMessages = payload
  }

}
export const actions = {
  fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return this.$axios
        .$get('/customers', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[trashed]': payload.trashed,
            'filter[email_verified]': payload.email_verified,
            'filter[created_at]': payload.date_range,
            'filter[country]': payload.country,
            'filter[customer_level]': payload.customer_level,
            'filter[gar_type]': payload.gar_type,
            'filter[role_name]': payload.role_name
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('LOADCUSTOMERS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/customers', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADCUSTOMERS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async getStatistics ({ commit }) {
    commit('SETLOADING', true)

    await this.$axios.$get('/statistics/customers').then((response) => {
      commit('SETLOADING', false)
      commit('SETSTATISTICS', response.data)
    })
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/customers', payload)
      commit('SETLOADING', false)
      commit('LOADCUSTOMERS', response.data)
      return response
    }
  },
  async getCustomer ({ commit }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$get(`/customers/${payload}`)
    commit('SETLOADING', false)
    commit('SETCUSTOMER', response.data)

    return response
  },
  addCustomer ({ commit, dispatch }, payload) {
    const response = this.$axios.$post('/customers', payload)
    dispatch('fetch')
    return response
  },
  async editCustomer ({ commit }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post(
      `/customers/${payload.id}`,
      payload.form_data
    )
    commit('SETCUSTOMER', response.data)
    commit('SETLOADING', false)

    return response
  },
  deleteCustomer ({ _ }, payload) {
    return this.$axios.$delete('/customers', {
      data: {
        ids: payload
      }
    })
  },
  bulkChanges ({ commit }, payload) {
    return this.$axios.$post('/customers', payload)
  },
  confirmDeletion ({ commit }, payload) {
    commit('SETLOADING', true)
    return this.$axios.$delete('/customers', {
      data: {
        ids: payload,
        confirm_deletion: true
      }
    })
  },
  changePassword ({ commit }, payload) {
    return this.$axios.$post(
      `/update-customer-password/${payload.id}`,
      payload.form_data
    )
  },
  sendInvitation ({ commit }, payload) {
    return this.$axios.$post('/customers/email-invitation', payload)
  },
  async getCountries ({ commit }) {
    return await this.$axios
      .$get('/countries', {
        params: {
          list: true
        }
      })
      .then((res) => {
        commit('SETCOUNTRIES', res.data)
      })
  },
  async getLevels ({ commit }) {
    return await this.$axios
      .$get('/customer-levels', {
        params: {
          list: true
        }
      })
      .then((res) => {
        commit('SETLEVEL', res.data)
      })
  },
  async logoutFromAllDevices ({ commit }, payload) {
    const response = await this.$axios.$get(
      `/customer-logout-devices/${payload}`
    )
    commit('SETCUSTOMER', response.data)
    return response
  },
  async checkForceDelete ({ commit }, customerId) {
    return await this.$axios
      .$get(`/customers-force-delete/${customerId}`).then((res) => {
        commit('SETCHECKDATA', res.data)
      }).catch((err) => {
        commit('SETCHECKDATA', err.response.data.data)
      })
  },
  async getCustomerMessages ({ commit }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$get(`/customers/${payload.customerId}/trengo-messages`, {
      params: {
        search_text: payload.search_text,
        page: payload.page,
        per_page: payload.per_page,
        orderBy: payload.orderBy,
        'filter[status]': payload.status ? payload.status : null
      }
    })
    commit('SETLOADING', false)
    commit('SETCUSTOMERMESSAGES', response.data)

    return response
  },
  async verifyingSocialMedia ({ commit }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post(
      `/customers/${payload.id}`,
      payload.form_data
    )
    commit('SETCUSTOMER', response.data)
    commit('SETLOADING', false)

    return response
  }
}

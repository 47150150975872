export const state = () => ({
  notifications: {},
  unreadNotifications: {},
  allNotifications: {}
})

export const mutations = {
  NOTIFICATIONS (state, payload) {
    state.notifications = payload
  },
  UNREADNOTIFICATIONS (state, payload) {
    state.unreadNotifications = payload
  },
  ALLNOTIFICATIONS (state, payload) {
    state.allNotifications = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    // if (payload !== null && payload !== undefined) {
    return await this.$axios
      .$get('/notifications', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy
        }
      })
      .then((response) => {
        commit('NOTIFICATIONS', response.data)
        commit('ALLNOTIFICATIONS', response.data.notifications.data)
        const arr = []
        response.data.notifications.data.forEach((item) => {
          if (item.status === 'unread') {
            arr.push({ ...item })
          }
        })
        commit('UNREADNOTIFICATIONS', arr)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async fetchtNotificationsLis ({ dispatch, commit }, payload) {
    return await this.$axios.$post('/notifications', payload)
  },
  async readNote ({ dispatch }, payload) {
    return await this.$axios.$post('/notifications', payload)
  }
}

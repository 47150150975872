export const state = () => ({
  statistics: {},
  subscription: {},
  subscriptions: {},
  SubscriptionRecords: {},
  loading: false
})

export const mutations = {
  SUBSCRIPTIONS (state, payload) {
    state.subscriptions = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  SETSUBSCRIPTION (state, payload) {
    state.subscription = payload
  },
  SUBSCRIPTION_RECORDS (state, payload) {
    state.SubscriptionRecords = payload
  }
}

export const actions = {
  fetch ({ commit }, payload) {
    commit('SETLOADING', true)
    return this.$axios
      .$get('/subscriptions', {
        params: {
          search_text: payload.search_text,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          'filter[customer_id]': payload.customer_id,
          'filter[customer_email]': payload.customer_email,
          // 'filter[subscribable]': payload.product,
          'filter[expired]': payload.expired,
          'filter[commentable_id]': payload.commentable_id,
          'filter[subscribable]': payload.subscribable
        }
      })
      .then((response) => {
        commit('SETLOADING', false)
        commit('SUBSCRIPTIONS', response.data)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async fetchSubscriptionRecords ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$get(`/subscriptions/${payload.subscriptionId}/subscription-records`, {
      params: {
        search_text: payload.search_text,
        page: payload.page,
        per_page: payload.per_page,
        'filter[created_at]': payload.created_at
      }
    })
      .then((response) => {
        commit('SETLOADING', false)
        commit('SUBSCRIPTION_RECORDS', response.data)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async editSubscription ({ commit }, payload) {
    const response = await this.$axios.$post('/subscriptions/', payload)
    return response
  },
  async showSubscription ({ commit }, subscriptionId) {
    commit('SETLOADING', true)
    return await this.$axios.$get(`/subscriptions/${subscriptionId}`).then((res) => {
      commit('SETLOADING', false)
      commit('SETSUBSCRIPTION', res.data)
    })
  },
  async updateActiveState ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('/subscriptions', payload)
    commit('SETLOADING', false)
    return response
  },
  async updateExpiredDate ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('/subscriptions', payload)
    commit('SETLOADING', false)
    return response
  },
  async updateExDate ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post(`/subscriptions/${payload.subscriptionId}`, payload.form_data)
    commit('SETLOADING', false)
    commit('SETSUBSCRIPTION', response.data)
    return response
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/subscriptions', payload)
      commit('SETLOADING', false)
      commit('SUBSCRIPTIONS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/subscriptions').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async applyProcedure ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('subscriptions', payload.form_data)

    // commit('RESPONSEMESSAGE', payload)
    return response
  }
}

export const state = () => ({
  frequentlyAskedQuestions: {},
  frequentlyAskedq: {},
  sectionTypes: [],
  faqCategories: [],
  loading: false,
  responseMessage: {
    successMessage: null,
    message: null
  }
})

export const mutations = {
  FAQS (state, payload) {
    state.frequentlyAskedQuestions = payload
  },
  RESPONSEMESSAGE (state, payload) {
    state.responseMessage.successMessage = payload.success_message
    state.responseMessage.message = payload.message
  },
  SECTIONTYPES (state, payload) {
    state.sectionTypes = payload
  },
  FAQCATEGORIES (state, payload) {
    state.faqCategories = payload
  },
  GETFAQ (state, payload) {
    state.frequentlyAskedq = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return this.$axios
      .$get('/faqs', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          'filter[section_type]': payload.section_type
        }
      })
      .then((response) => {
        commit('SETLOADING', false)

        commit('FAQS', response.data)
      })
      .catch((e) => {
        console.log(e)
      })
  },

  async fetch_sections_types ({ commit }, payload) {
    return await this.$axios
      .$get('/faq-categories', {
        params: {
          list: true
        }
      })
      .then((response) => {
        commit('SECTIONTYPES', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async fetch_faq_categories ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/faq-categories', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[is_available]': payload.is_available
        }
      })

      .then((response) => {
        commit('FAQCATEGORIES', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getFaq ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get(`faqs/${payload}`)
      .then((response) => {
        commit('GETFAQ', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async AddNewFaq ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('/faqs', payload)
    commit('SETLOADING', false)
    return response
  },
  async AddNewFaqCategories ({ commit }, payload) {
    return await this.$axios.$post('/faq-categories', payload)
  },
  async updateFaq ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post(
      `faqs/${payload.id}`,
      payload.form_data
    )
    commit('SETLOADING', false)
    return response
  },
  async updatFaqCategory ({ commit }, payload) {
    return await this.$axios.$post(
      `/faq-categories/${payload.id}`,
      payload.form_data
    )
  },
  async update_faq_available ({ commit }, payload) {
    const response = await this.$axios.$post(
      `/faq-categories/${payload.id}`,
      payload.form_data
    )
    return response
  },

  async deleteFaq ({ commit, dispatch }, payload) {
    const response = await this.$axios.$delete('/faqs', {
      params: {
        ids: payload
      }
    })
    return response
  },
  async deleteFaqCategories ({ dispatch, commit }, payload) {
    return await this.$axios.$delete('/faq-categories', {
      params: {
        ids: payload
      }
    })
  }
}

export const state = () => ({
  neglected: [],
  loading: false

})

export const mutations = {
  LOADNEGLECTED (state, payload) {
    state.neglected = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }

}
export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    if (payload !== null && payload !== undefined) {
      return await this.$axios
        .$get('/abandoned-carts', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[date_range]': payload.date_range

          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADNEGLECTED', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      return await this.$axios
        .$get('/abandoned-carts', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADNEGLECTED', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },

  async sendMessage ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post('/abandoned-carts/send-messages', payload)
    dispatch('fetch')
    return response
  }

}

export const state = () => ({
  discounts: [],
  discount: null,
  loading: false,
  countryiesList: [],
  statistics: {},
  productsList: [],
  customers: []
})
export const mutations = {
  LOADDISCOUNTS (state, payload) {
    state.discounts = payload
  },
  ADDDISCOUNT (state, payload) {
    state.discounts.push({
      ...payload,
      discount_image: '',
      views: '0',
      shares: '0',
      comments: '0'
    })
  },
  DELETEDISCOUNT (state, discountIndex) {
    state.discounts.discounts.splice(discountIndex, 1)
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETDISCOUNT (state, payload) {
    state.discount = payload
  },
  SETPRODUCTS (state, payload) {
    state.productsList = payload
  },
  SETCUSTOMERS (state, payload) {
    state.customers = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return this.$axios
        .$get('/discounts', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[type]': payload.type,
            'filter[active]': payload.active,
            'filter[trashed]': payload.trashed
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('LOADDISCOUNTS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/discounts', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADDISCOUNTS', response.data)
        }).catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/discounts', payload)
      commit('SETLOADING', false)
      commit('LOADDISCOUNTS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/discounts').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },

  async getDiscount ({ commit }, payload) {
    commit('SETLOADING', true)

    return await this.$axios.$get(`/discounts/${payload}`).then((res) => {
      commit('SETLOADING', false)

      commit('SETDISCOUNT', res.data)
    })
  },
  async addDiscount ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post('/discounts', payload)
    dispatch('fetch')
    return response
  },
  async editDiscount ({ commit, dispatch }, payload) {
    const response = await this.$axios
      .$post(`/discounts/${payload.id}`, payload.form_data)
    dispatch('fetch')
    return response
  },
  deleteDiscount ({ commit }, payload) {
    return this.$axios
      .$delete('/discounts', {
        data: {
          ids: [payload]
        }
      })
    // commit('DELETEDISCOUNT', discountIndex)
  },
  async getProducts ({ commit }) {
    return await this.$axios.$get('/discounts/products').then((res) => {
      commit('SETPRODUCTS', res.data)
    })
  },
  async getCustomers ({ commit }, payload) {
    return await this.$axios
      .$get('discounts/customers', {
        params: {
          search_text: payload.search_text,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy
        }
      }).then((res) => {
        commit('SETCUSTOMERS', res.data)
      })
  }
}

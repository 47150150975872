export const state = () => ({
  banners: null,
  loading: false
})

export const mutations = {
  LOADBANNERS (state, payload) {
    state.banners = payload
  },
  ADDCATEGORY (state, payload) {
    state.banners.push(payload)
  },
  EDITCATEGORY (state, payload) {
    state.banners[payload.index] = payload.banner
  },
  DELETECATEGORY (state, bannerIndex) {
    state.banners.splice(bannerIndex, 1)
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/banners', {
        params: {
          search_text: payload.search_text,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy
        }
      })
      .then((response) => {
        commit('SETLOADING', false)
        commit('LOADBANNERS', response.data)
      })
      .catch(() => {
        commit('SETLOADING', false)
      })
  },
  async add ({ _ }, payload) {
    const response = await this.$axios.$post('/banners', payload)
    return response
  },
  async update ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post(
      `/banners/${payload.id}`,
      payload.form_data
    )
    return response
  },
  async delete ({ _ }, bannerId) {
    const response = await this.$axios.$delete(`/banners/${bannerId}`)
    return response
  },

  async downloadBannerImage ({ _ }, bannerId) {
    const response = await this.$axios.$get(
      `/banners/download-banner-image/${bannerId}`, {
        responseType: 'blob'
      }
    )
    return response
  }
}

export const state = () => ({
  pages: {},
  page: null,
  loading: false,
  YearCustomersgetStatistics: {}
})

export const mutations = {
  PAGES (state, payload) {
    state.pages = payload
  },
  SET_PAGE (state, payload) {
    state.page = payload
  },
  SETNEWYEARSTATISTICS (state, payload) {
    state.YearCustomersgetStatistics = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/pages', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          search_text: payload.search_text
        }
      })
      .then((response) => {
        commit('PAGES', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async getPage ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$get(`/pages/${payload}`).then((res) => {
      commit('SETLOADING', false)
      commit('SET_PAGE', res.data)
    })
  },
  async deletePage ({ dispatch, commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$delete('/pages', {
      data: {
        ids: [payload]
      }
    })
    // return await this.$axios.$post('/notifications', payload)
  },
  async pagesDuplicate ({ commit }, payload) {
    const response = await this.$axios.$post('/pages/duplicate', payload)
    return response
  },
  async addPage ({ commit }, payload) {
    return await this.$axios.$post('/pages', payload)
  },
  async editPage ({ commit }, payload) {
    return await this.$axios.$post(`/pages/${payload.id}`, payload.form_data)
  },
  async importCustomersForNewYear ({ _ }, payload) {
    return await this.$axios
      .$post('register-in-firestore-db', payload)
  },
  async getNewYearCustomersgetStatistics ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$get('register-in-firestore-db').then((res) => {
      commit('SETLOADING', false)
      commit('SETNEWYEARSTATISTICS', res.data)
    })
  }
}

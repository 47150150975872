export const state = () => ({
  categories: [],
  categoriesList: [],
  snackBar: {
    text: null
  },
  loading: false
})
export const mutations = {
  LOADACATEGORIES (state, payload) {
    state.categories = payload
  },
  ADDCATEGORYLIST (state, payload) {
    state.categoriesList = payload
  },
  ADDCATEGORY (state, payload) {
    state.categories.push(payload)
  },
  EDITCATEGORY (state, payload) {
    state.categories[payload.index] = payload.category
  },
  DELETECATEGORY (state, categoryIndex) {
    state.categories.splice(categoryIndex, 1)
  },

  SHOWSNACKBAR (state, snackBarText) {
    state.snackBar.text = snackBarText
  },
  HIDESNACKBAR (state) {
    state.snackBar.text = null
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },

  UPDATECATEGORY (state, payload) {
    state.categories.categories = payload
  }
}
export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    dispatch('fetchList')
    if (payload !== null && payload !== undefined) {
      return await this.$axios
        .$get('/categories', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            locale: 'ar'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADACATEGORIES', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      return await this.$axios
        .$get('/categories', {
          params: {
            search_text: '',
            page: 1,
            per_page: 6,
            orderBy: 'created_at',
            locale: 'ar'
          }
        })
        .then((response) => {
          // response.data.categories.forEach((category) => {
          commit('SETLOADING', false)
          commit('LOADACATEGORIES', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/categories', payload)
      commit('SETLOADING', false)
      commit('LOADACATEGORIES', response.data)
      return response
    }
  },
  async fetchList ({ commit }) {
    return await this.$axios
      .$get('/categories', {
        params: {
          list: true
        }
      })
      .then((response) => {
        commit('ADDCATEGORYLIST', response.data.categories)
      })
  },
  async addCategory ({ commit, dispatch }, payload) {
    return await this.$axios.$post('/categories', payload)
  },
  async editCategory ({ commit }, payload) {
    return await this.$axios.$post(
      `/categories/${payload.id}`,
      payload.form_data
    )
  },
  deleteCategory ({ commit, dispatch }, categoryIndex) {
    return this.$axios.$delete('/categories', {
      data: {
        ids: [categoryIndex]
      }
    })
  }
}

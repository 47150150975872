
// import { getMessaging, onMessage } from 'firebase/messaging'
import headerComponent from '~/components/headerComponent.vue'
export default {
  components: {
    headerComponent
  },
  async fetch ({ store }) {
    await store.dispatch('notifications/fetch', {
      per_page: 6,
      page: 1,
      orderBy: 'created_at'
    })
  },
  data () {
    return {
      display: true
    }
  },
  beforeCreate () {
    this.$vuetify.rtl = true
  },
  async created () {
    await this.$store.dispatch('notifications/fetch', {
      per_page: 6,
      page: 1,
      orderBy: 'created_at'
    })
  },
  methods: {
    showNotes () {
    //
    }
  }
}

export const state = () => ({
  statistics: {},
  levels: [],
  loading: false,
  level: null
})

export const mutations = {
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETLEVELS (state, payload) {
    state.levels = payload
  },
  SETLEVEL (state, payload) {
    state.level = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get('/customer-levels', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('SETLEVELS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/customer-levels', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('SETLEVELS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/customer-level').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async create ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post('/customer-levels', payload)
    commit('SETLOADING', false)
    dispatch('fetch')
    return response
  },
  async update ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post(
      `/customer-levels/${payload.id}`,
      payload.form_data
    )
    dispatch('fetch')
    return response
  },
  async get ({ commit }, levelId) {
    commit('SETLOADING', true)
    const response = await this.$axios.$get(`/customer-levels/${levelId}`)
    commit('SETLEVEL', response.data)
    commit('SETLOADING', false)
    return response
  },
  async delete ({ dispatch }, payload) {
    const response = await this.$axios.$delete('/customer-levels', {
      data: {
        ids: [payload]
      }
    })
    dispatch('fetch')
    return response
  }
}

export const state = () => ({
  settings: {},
  scheduledTasks: {}
})

export const mutations = {
  SETSETTINGS (state, payload) {
    state.settings = payload
  },
  SETSCHEDULEDTASKS (state, payload) {
    state.scheduledTasks = payload
  }
}
export const actions = {
  async fetch ({ commit }) {
    const response = await this.$axios.$get('/settings/general')
    commit('SETSETTINGS', response.data)
    return response
  },
  async updateGeneral ({ commit }, payload) {
    const response = await this.$axios.$post('/settings/general', payload)
    commit('SETSETTINGS', response.data)
    return response
  },
  async  clearCache ({ _ }) {
    return await this.$axios.$get('/settings/clear-response-cache')
  },

  async  getScheduledTasks ({ commit }) {
    const response = await this.$axios.$get('/settings/scheduler-tasks')
    commit('SETSCHEDULEDTASKS', response.data)
    return response
  },

  async updateScheduledTasks ({ commit }, payload) {
    const response = await this.$axios.$post('/settings/scheduler-tasks', payload)
    commit('SETSETTINGS', response.data)
    return response
  }
}

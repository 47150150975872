export const state = () => ({
  statistics: {},
  favorites: [],
  loading: false,
  favoritesList: []
})
export const mutations = {
  LOADFAVORITES (state, payload) {
    state.favorites = payload
  },
  LOADORDERS (state, payload) {
    state.orders = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  SETFAVORITES (state, payload) {
    state.favoritesList = payload
  }
}
export const actions = {
  fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return this.$axios
        .$get('/favorites', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[customer_id]': payload.customer_id,
            'filter[favoriteable]': payload.favoriteable_id,
            'filter[customer_email]': payload.customer_email
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('LOADFAVORITES', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/favorites', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADFAVORITES', response.data)
        }).catch(() => {
          commit('SETLOADING', false)
        })
    }
  },

  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/favorites', payload)
      commit('SETLOADING', false)
      commit('LOADFAVORITES', response.data)
      return response
    }
  },

  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/favorites').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },

  async getFavorites ({ commit }) {
    return await this.$axios.$get('/favorites/favorites-list').then((res) => {
      commit('SETFAVORITES', res.data)
    })
  }

}

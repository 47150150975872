export const state = () => ({
  successMessage: '',
  appeared: false,
  statistics: null,
  errorMessage: {},
  loginForFirstTime: null,
  status_code: null
})

export const mutations = {
  SET_ADMIN (state, payload) {
    state.admin = payload
  },
  RESPONSEMESSAGE (state, payload) {
    state.successMessage = payload.message
  },
  MESSAGEAPPEARED (state, payload) {
    state.appeared = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async adminLogin ({ commit }, formData) {
    const response = await this.$auth.loginWith('local', {
      data: formData
    })

    localStorage.setItem('subtoken', response.data.data.token.substring(
      response.data.data.token.length / 2,
      response.data.data.token.length / 2 + 10
    ))

    commit('RESPONSEMESSAGE', response.data)
    return response
  },
  async adminLogout ({ commit }, payload) {
    const response = await this.$axios.$post('/logout', {
      device_id: payload
    })

    localStorage.removeItem('subtoken')

    return response
  },
  async activateEmail ({ commit }, formData) {
    const response = await this.$axios.$post(
      '/resend-activation-email',
      formData
    )

    return response
  },
  async checkEmailActivate ({ commit }, payload) {
    const response = await this.$axios.$post('/activate-account', payload)

    return response
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  }
}

export const state = () => ({
  desiers: [],
  desiersList: [],
  customer_desires: [],
  loading: false
})

export const mutations = {
  SETLOADING (state, payload) {
    state.loading = payload
  },
  DESIRES (state, payload) {
    state.desiers = payload
  },
  DESIRESLIST (state, payload) {
    state.desiersList = payload
  },
  CUSTOMERDESIRES (state, payload) {
    state.customer_desires = payload
  }
}
export const actions = {
  async fetch ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/desires')
      .then((response) => {
        commit('SETLOADING', false)

        commit('DESIRESLIST', response.data)
      })
      .catch(() => {
        commit('SETLOADING', false)
      })
  },
  async fetchCustoemrDesires ({ commit }, payload) {
    if (payload && payload.page) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get(`customers/${payload.customerId}/desires`, {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page
            // 'filter[desires_id]': payload.pointId

          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('CUSTOMERDESIRES', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get(`customers/${payload.customerId}/desires`, {
          params: {
            search_text: ''
            // page: 1,
            // per_page: 10
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('CUSTOMERDESIRES', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async addCustomerDesire ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post(`customers/${payload.customerId}/desires`, {
      id: payload.id
    })
    return response
  },
  async deleteDesires ({ commit, dispatch }, payload) {
    const response = await this.$axios.$delete(`customers/${payload.customerId}/desires`, {
      params: {
        ids: payload.ids
      }
    })

    return response
  }
}

export const state = () => ({
  aboutUs: {}
})

export const mutations = {
  SETABOUTUS (state, payload) {
    state.aboutUs = payload
  }
}
export const actions = {
  async fetch ({ commit }) {
    const response = await this.$axios.$get('/settings/about-us')
    commit('SETABOUTUS', response.data.about_us)
    return response
  },
  async updateAboutUs ({ commit }, payload) {
    const response = await this.$axios.$post('/settings/about-us', payload)
    commit('SETABOUTUS', response.data.about_us)
    return response
  }
}

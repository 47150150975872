import Vue from 'vue'

export default () => {
  Vue.filter('textLengthFilter', (text = '', length, suffix = '...') => {
    if (text.length > length) {
      return text.substring(0, length) + suffix
    } else {
      return text
    }
  })
  Vue.filter('typeToArabicFilter', (text = '') => {
    switch (text) {
      case 'course':
        return 'مادة '
      case 'ebook':
        return 'كتاب إلكتروني'
      case 'bundle':
        return 'باقة'
      case 'article':
        return 'مقال'
      case 'publication':
        return 'إصدار'
      case 'books':
      case 'book':
        return 'كتاب'
      case 'cards':
      case 'card':
        return 'بطاقة'
      case 'albums':
      case 'album':
        return 'إلبوم'
      case 'auto-complete':
        return 'قبول تلقائي'
      case 'accepted':
        return 'مقبول'
      case 'pending':
        return 'قيد المراجعة'
      case 'rejected':
        return 'مرفوض'
      case 'credit_card':
        return 'كريدت كارد'
      case 'bank_transfer':
        return 'حوالة بنكية'
      case 'pending_payment':
        return 'بانتظار الدفع'
      case 'free_order':
        return 'طلب مجاني'
      case 'payment_rejected':
        return 'فشل الدفع'
      case 'refunded':
        return 'مسترجع'
      default:
        return text
    }
  })
}

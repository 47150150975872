export const state = () => ({
  cards: {},
  statistics: {},
  card: {},
  loading: false
})

export const mutations = {
  SETCARDS (state, payload) {
    state.cards = payload
  },
  SETCARD (state, payload) {
    state.card = payload
  },
  ADDTAG (state, payload) {
    state.card.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.card.tag_names.splice(payload, 1)
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      const response = await this.$axios.$get(
        '/publications?filter[type]=cards',
        {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[can_comment]': payload.is_purchasable,
            'filter[active]': payload.active,
            'filter[trashed]': payload.trashed
          }
        }
      )
      commit('SETCARDS', response.data)
      return response
    } else {
      const response = await this.$axios.$get(
        '/publications?filter[type]=cards',
        {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        }
      )
      commit('SETCARDS', response.data)
      return response
    }
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post(
        '/publications?type=cards',
        payload
      )
      commit('SETLOADING', false)
      commit('SETCARDS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios
      .$get('/statistics/publications?type=cards')
      .then((response) => {
        commit('SETSTATISTICS', response.data)
      })
  },
  async getCard ({ commit }, payload) {
    return await this.$axios
      .$get(`/publications/cards/${payload}`)
      .then((response) => {
        commit('SETCARD', response.data)
      })
  },
  addCard ({ commit }, payload) {
    return this.$axios.$post('/publications/cards', payload)
  },
  editCard ({ commit }, payload) {
    return this.$axios.$post(
      `/publications/cards/${payload.id}`,
      payload.form_data
    )
  },
  async deleteCard ({ commit }, payload) {
    const response = await this.$axios.$delete('/publications/cards', {
      data: {
        ids: [payload]
      }
    })
    return response
  }
}

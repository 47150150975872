
// import { getMessaging, onMessage } from 'firebase/messaging'
export default {
  data () {
    return {
      total_unread_notifications: '',
      search_text: '',
      show: false,
      items: 3,
      i: 0,
      pageNumber: 1,
      all: false,
      loading: false,
      unreads: [],
      notificationsList: [],
      readable_naotifications: [],
      unread_naotifications: []
    }
  },
  fetch ({ store }) {
    store.dispatch('notifications/fetch', {
      per_page: 6,
      page: 1,
      orderBy: 'created_at'
    })
  },
  computed: {
    response () {
      return this.$store.state.notifications.notifications
    },
    fetch_notifications () {
      return this.response.notifications
    },
    // allNotifications () {
    //   return this.$store.state.notifications.allNotifications
    // },
    fetch_unreadNotifications () {
      return this.$store.state.notifications.unreadNotifications
    }
  },
  watch: {
    '$store.state.notifications.notifications.notifications.data' () {
      if (this.fetch_notifications) {
        this.notificationsList.push(
          ...this.$store.state.notifications.notifications.notifications.data
        )
      }
    }
  },
  mounted () {
    const imgss = 'https://i.ibb.co/g4BR50S/avatar.png'
    const { messaging } = this.$fire
    if (messaging !== undefined) {
      messaging.onMessage((payload) => {
        console.info('Message received111. ', payload.notification.body)
        if (messaging.onMessage) {
          this.$notify({
            group: 'note',
            // type: 'success',
            title: payload.notification.title,
            text:
              `<div style="width: 40px;position: relative;bottom: 22px;"><img src="${imgss}" style="width: 35px; padding-bottom: 12px;"></div>` +
              payload.notification.body
            // duration: 1000000
          })
          this.$store.dispatch('notifications/fetch', {
            per_page: 6,
            page: 1,
            orderBy: 'created_at'
          })
        }
      })
    }
  },
  created () {
    this.$store.dispatch('notifications/fetch', {
      per_page: 6,
      page: 1,
      orderBy: 'created_at'
    })
  },
  methods: {
    async admin_logout () {
      await this.$store.commit('MESSAGEAPPEARED', false)
      await this.$store
        .dispatch('adminLogout', localStorage.getItem('divceId'))
        .then(() => {
          this.$router.push('/login')
        })
      // this.$auth.logout({
      //   device_id: localStorage.getItem('divceId')
      // })
      // setTimeout(() => {
      //   window.location.reload(true)
      // }, 2000)
    },
    async changStatus (note) {
      this.notificationsList = []
      await this.$store
        .dispatch('notifications/readNote', {
          ids: note.id
        })
        .then(() => {
          this.$store.dispatch('notifications/fetch', {
            per_page: 6,
            page: 1,
            // page: this.fetch_notifications.current_page
            orderBy: 'created_at'
          })
        })
    },
    async readAllNotifications () {
      this.notificationsList = []
      await this.$store
        .dispatch('notifications/fetchtNotificationsLis', {
          read_all: true
        })
        .then(async () => {
          await this.$store.dispatch('notifications/fetch', {
            per_page: 6,
            page: 1,
            // page: this.fetch_notifications.current_page
            orderBy: 'created_at'
          })
        })

      this.show = false
    },
    async scrollHandler ($state) {
      if (
        this.fetch_notifications.current_page !==
        this.fetch_notifications.last_page
      ) {
        await this.$store
          .dispatch('notifications/fetch', {
            page: this.fetch_notifications.current_page + 1
          })
          .then(() => {
            $state.loaded()
          })
          .catch((error) => {
            console.log('Error ', error)
          })
      } else {
        $state.complete()
      }
    },
    showNotes () {
      this.show = !this.show
    },
    async showMoreNotes (currentpage) {
      this.show = true
      this.loading = true
      if (
        this.fetch_notifications.current_page !==
        this.fetch_notifications.last_page
      ) {
        await this.$store.dispatch('notifications/fetch', {
          page: this.fetch_notifications.current_page + 1

        })
      }
      this.loading = false
    },
    onClickOutside () {
      this.show = false
    }
  }
}

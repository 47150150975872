export const state = () => ({
  tags: [],
  tagList: [],
  snackBar: {
    text: null
  },
  loading: false
})
export const mutations = {
  LOADATAGS (state, payload) {
    state.tags = payload
  },
  ADDTAGLIST (state, payload) {
    state.tagList = payload
  },
  ADDTAG (state, payload) {
    state.tags.push(payload)
  },
  EDITTAG (state, payload) {
    state.tags[payload.index] = payload.tags
  },
  DELETETAG (state, tagIndex) {
    state.tags.splice(tagIndex, 1)
  },

  SHOWSNACKBAR (state, snackBarText) {
    state.snackBar.text = snackBarText
  },
  HIDESNACKBAR (state) {
    state.snackBar.text = null
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },

  UPDATETAG (state, payload) {
    state.tags.tags = payload
  }
}
export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    dispatch('fetchList')
    if (payload !== null && payload !== undefined) {
      return await this.$axios
        .$get('/tags', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            locale: 'ar'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADATAGS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      return await this.$axios
        .$get('/tags', {
          params: {
            search_text: '',
            page: 1,
            per_page: 6,
            orderBy: 'created_at',
            locale: 'ar'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADATAGS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/tags', payload)
      commit('SETLOADING', false)
      commit('LOADATAGS', response.data)
      return response
    }
  },
  async fetchList ({ commit }) {
    return await this.$axios
      .$get('/tags', {
        params: {
          list: true
        }
      })
      .then((response) => {
        commit('ADDTAGLIST', response.data.tags)
      })
  },
  async addTag ({ commit, dispatch }, payload) {
    return await this.$axios.$post('/tags', payload)
  },
  async editTag ({ commit }, payload) {
    return await this.$axios.$post(`/tags/${payload.id}`, payload.form_data)
  },
  deleteTag ({ commit, dispatch }, tagIndex) {
    return this.$axios.$delete('/tags', {
      data: {
        ids: [tagIndex]
      }
    })
  }
}

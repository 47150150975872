export const state = () => ({
  roles: [],
  role: {},
  permissions: [],
  rolesList: [],
  snackBar: {
    text: null
  },
  loading: false
})
export const mutations = {
  LOADAROLES (state, payload) {
    state.roles = payload
  },
  ADDROLELIST (state, payload) {
    state.rolesList = payload
  },
  ADDROLE (state, payload) {
    state.role = payload
  },
  EDITROLE (state, payload) {
    state.roles[payload.index] = payload.role
  },
  DELETEROLE (state, roleIndex) {
    state.roles.splice(roleIndex, 1)
  },

  SHOWSNACKBAR (state, snackBarText) {
    state.snackBar.text = snackBarText
  },
  HIDESNACKBAR (state) {
    state.snackBar.text = null
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },

  UPDATEROLE (state, payload) {
    state.roles.roles = payload
  },
  ADDPERMISSIONS (state, payload) {
    state.permissions = payload
  }

}
export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    if (payload !== null && payload !== undefined) {
      return await this.$axios
        .$get('/roles', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            locale: 'ar'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADAROLES', response.data)
        }).catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      return await this.$axios.$get('/roles', {
        params: {
          search_text: '',
          page: 1,
          per_page: 6,
          orderBy: 'created_at',
          locale: 'ar'
        }
      }

      ).then((response) => {
        // response.data.roles.forEach((role) => {
        commit('SETLOADING', false)
        commit('LOADAROLES', response.data)
      }).catch(() => {
        commit('SETLOADING', false)
      })
    }
  },
  getRolesList ({ commit }) {
    return this.$axios
      .$get('/roles', {
        params: {
          list: true
        }
      })
      .then((response) => {
        commit('ADDROLELIST', response.data.roles)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  async createRole ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post('/roles', payload)
    dispatch('fetch')
    return response
  },
  async editRole ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post(`/roles/${payload.id}`, payload.form_data)
    dispatch('fetch')
    return response
  },
  async getRole ({ commit }, payload) {
    return await this.$axios.$get(`/roles/${payload}`).then((response) => {
      commit('ADDROLE', response.data)
    })
  },
  deleteRole ({ commit, dispatch }, roleIndex) {
    return this.$axios.$delete('/roles', {
      data: {
        ids: [roleIndex]
      }
    })
  },
  async getAllPermissions ({ commit }) {
    return await this.$axios.$get('/permissions').then((response) => {
      commit('ADDPERMISSIONS', response.data.all_permissions)
    })
  }

}

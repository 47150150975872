export const state = () => ({
  gifts: {},
  ListGARS: {},
  loading: false,
  waitingGiftsCount: null,
  pendingGiftsCount: null
})

export const mutations = {
  SETGIFTS  (state, payload) {
    state.gifts = payload
  },
  SET_lIST_GARS  (state, payload) {
    state.ListGARS = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETWAITINGGIFTSCOUNT (state, payload) {
    state.waitingGiftsCount = payload
  },
  SETPENDINGGIFTSCOUNT (state, payload) {
    state.pendingGiftsCount = payload
  }

}
export const actions = {
  fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return this.$axios
        .$get('/gifts', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            gift_to_unregistered_customers: payload.gift_to_unregistered_customers !== null && payload.gift_to_unregistered_customers !== undefined ? payload.gift_to_unregistered_customers.toString() : null,
            'filter[status]': payload.status ? payload.status : null,
            'filter[type]': payload.type ? payload.type : null,
            'filter[is_gifted_to_unknown]': payload.is_gifted_to_unknown || payload.is_gifted_to_unknown === false ? payload.is_gifted_to_unknown : null,
            'filter[converted]': payload.converted || payload.converted === false ? payload.converted.toString() : null
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('SETGIFTS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/gifts', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('SETGIFTS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async fetchlistGAR ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get(`/gifts/${payload.giftId}/list-gars`, {
        params: {
          search_text: payload.search_text
        }
      })
      .then((res) => {
        commit('SETLOADING', false)
        commit('SET_lIST_GARS', res.data)
      })
  },
  async moveGifttoCustomer ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$post('/gifts/convert-gift-to-unknown', payload)
      .then((res) => {
        commit('SETLOADING', false)
        return res
      })
      // .catch((error) => {
      //   commit('SETLOADING', false)
      //   console.log(error)
      // })
  },

  getWaitingGiftsCount ({ commit }) {
    return this.$axios
      .$get('/gifts', {
        params: {
          search_text: '',
          page: 1,
          per_page: 10,
          orderBy: 'created_at',
          'filter[is_gifted_to_unknown]': true,
          'filter[status]': 'completed',
          'filter[converted]': false
        }
      })
      .then((response) => {
        commit('SETLOADING', false)
        commit('SETWAITINGGIFTSCOUNT', response.data.total)
      })
      .catch(() => {
        commit('SETLOADING', false)
      })
  },
  getPendingGiftsCount ({ commit }) {
    return this.$axios
      .$get('/gifts', {
        params: {
          search_text: '',
          page: 1,
          per_page: 10,
          orderBy: 'created_at',
          gift_to_unregistered_customers: true,
          'filter[status]': 'completed',
          'filter[is_gifted_to_unknown]': false,
          'filter[converted]': false
        }
      })
      .then((response) => {
        commit('SETPENDINGGIFTSCOUNT', response.data.total)
      })
  }

}

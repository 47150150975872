export const state = () => ({
  bankAccounts: [],
  bankAccount: [],
  countries: {},
  loading: false
})
export const mutations = {
  BANKACCOUNTS (state, payload) {
    state.bankAccounts = payload
  },
  COUNTRIES (state, payload) {
    state.countries = payload.countries
  },
  SETBANKACCOUNT (state, payload) {
    state.bankAccount = payload
  },
  ADDBANKACCOUNT (state, payload) {
    state.bankAccounts.push(payload)
  },

  DELETEBANKACCOUNT (state, tagIndex) {
    state.bankAccounts.splice(tagIndex, 1)
  },

  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  fetch ({ commit }, payload) {
    commit('SETLOADING', true)
    return this.$axios
      .$get('/bank-accounts', {
        params: {
          search_text: payload.search_text,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy
        }
      })
      .then((response) => {
        commit('SETLOADING', false)
        commit('BANKACCOUNTS', response.data)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/bank-accounts', payload)
      commit('SETLOADING', false)
      commit('BANKACCOUNTS', response.data)
      return response
    }
  },
  fetchCountries ({ commit }) {
    return this.$axios
      .$get('/countries', {
        params: {
          list: true
        }
      })
      .then((response) => {
        commit('COUNTRIES', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  addEBankAccount ({ commit }, payload) {
    const response = this.$axios.$post('/bank-accounts', payload)
    return response
  },

  editBankAccount ({ commit }, payload) {
    return this.$axios.$post(`/bank-accounts/${payload.id}`, payload.form_data)
  },

  async getBankAccount ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get(`/bank-accounts/${payload}`)
      .then((response) => {
        commit('SETLOADING', false)
        commit('SETBANKACCOUNT', response.data)
      })
  },

  async deleteBankAccount ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$delete('/bank-accounts', {
      params: {
        ids: payload
      }
    })
    commit('SETLOADING', true)
    return response
  }
}


export default {
  data () {
    return {
      showVersionsOptions: false,
      showPagesOptions: false,
      showLoginHistoryOptions: false,
      showUsersOptions: false,
      showContentOptions: false,
      showGiftOptions: false,
      showMarketingOptions: false,
      showInterActiveOptions: false,
      showHistoriesOptions: false,
      openSettingsOptions: false,
      x: '~/assets/svg/list-items-icons/books.svg',
      size: this.$vuetify.breakpoint.name,
      drawer: true,
      environment_mode: process.env.NODE_ENV
    }
  },
  computed: {
    permissions () {
      return this.$auth.user.permissions
    },
    waitingGiftsCount () {
      return this.$store.state.gifts.waitingGiftsCount
    },
    pendingGiftsCount () {
      return this.$store.state.gifts.pendingGiftsCount
    }
  },
  watch: {
    '$vuetify.breakpoint.name': {
      handler (val) {
        if (val === 'lg' || val === 'xl') {
          this.drawe = true
        } else {
          this.drawe = false
        }
      }
    },
    '$route.name' (val) {
      if (
        val !== 'settings-general-settings' ||
        val !== 'settings-roles' ||
        val !== 'settings-roles-add-role' ||
        val !== 'settings-bank-accounts'
      ) {
        this.openSettingsOptions = false
      }
    }
  },
  mounted () {
    if (
      this.$vuetify.breakpoint.name === 'lg' ||
      this.$vuetify.breakpoint.name === 'xl'
    ) {
      this.drawe = true
    } else {
      this.drawe = false
    }
    this.$store.dispatch('gifts/getWaitingGiftsCount')
    this.$store.dispatch('gifts/getPendingGiftsCount')
  }
}

export const state = () => ({
  articles: [],
  statistics: {},
  article: null,
  loading: false
})
export const mutations = {
  LOADARTICLES (state, payload) {
    state.articles = payload
  },
  ADDARTICLE (state, payload) {
    state.articles = payload
  },
  EDITARTICLE (state, payload) {
    state.articles.articles[payload.index] = payload.article
  },
  DELETEARTICLE (state, articleIndex) {
    state.articles.articles.splice(articleIndex, 1)
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETARTICLE (state, payload) {
    state.article = payload
  },
  ADDTAG (state, payload) {
    state.article.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.article.tag_names.splice(payload, 1)
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return this.$axios
        .$get('/articles', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[can_comment]': payload.is_purchasable,
            'filter[active]': payload.active,
            'filter[trashed]': payload.trashed
          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('LOADARTICLES', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)

      return this.$axios
        .$get('/articles', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('LOADARTICLES', response.data)
        }).catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/articles', payload)
      commit('SETLOADING', false)
      commit('ADDARTICLE', response.data)
      return response
    }
  },

  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/articles').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async getArticle ({ commit }, payload) {
    return await this.$axios.$get(`/articles/${payload}`).then((res) => {
      commit('SETARTICLE', res.data)
    })
  },
  addArticle ({ commit, dispatch }, payload) {
    return this.$axios.$post('/articles', payload)
  },
  editArticle ({ commit }, payload) {
    return this.$axios
      .$post(`/articles/${payload.id}`, payload.form_data)
  },
  deleteArticle ({ commit }, payload) {
    return this.$axios
      .$delete('/articles', {
        data: {
          ids: [payload]
        }
      })
    // commit('DELETEARTICLE', articleIndex)
  }
}

export const state = () => ({
  notificationTemplate: {},
  notificationCases: {},
  Notification: null,
  productsList: [],
  customerList: [],
  targetCusotmerId: [],
  loading: false
})

export const mutations = {
  NOTIFICATIONTEMPLATE (state, payload) {
    state.notificationTemplate = payload
  },
  SETNOTIFICATION (state, payload) {
    state.Notification = payload
  },
  SET_NOTIFICATION_CASES (state, payload) {
    state.notificationCases = payload
  },
  PRODUCT_LIST (state, payload) {
    state.productsList = payload
  },
  CUSTOMER_LIST (state, payload) {
    state.customerList = payload
  },
  TARGET_CUSTOMER_ID (state, payload) {
    state.targetCusotmerId = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/notifications-template', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy
        }
      })
      .then((response) => {
        commit('NOTIFICATIONTEMPLATE', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async getNotification ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get(`/notifications-template/${payload}`)
      .then((res) => {
        commit('SETLOADING', false)
        commit('SETNOTIFICATION', res.data)
      })
  },
  async getNotificationsTemplateCases ({ commit }, payload) {
    return await this.$axios
      .$get('/notifications-template-cases')
      .then((res) => {
        commit('SET_NOTIFICATION_CASES', res.data)
      })
  },
  async deleteNotifications ({ dispatch, commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$delete('/notifications-template', {
      data: {
        ids: payload
      }
    })
    // return await this.$axios.$post('/notifications', payload)
  },
  async sendNotifications ({ dispatch, commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$post('/send-notifications-template', payload)
  },
  async addNotification ({ commit }, payload) {
    return await this.$axios.$post('/notifications-template', payload)
  },
  async editNotification ({ commit }, payload) {
    return await this.$axios.$post(
      `/notifications-template/${payload.id}`,
      payload.form_data
    )
  },
  async fetch_products ({ commit }, payload) {
    return await this.$axios.$get('/discounts/products').then((response) => {
      commit('PRODUCT_LIST', response.data)
    })
  },
  async fetch_customers ({ commit }, payload) {
    return await this.$axios
      .$get('/customers', {
        params: {
          search_text: payload.search_text,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy
        }
      })
      .then((response) => {
        commit('CUSTOMER_LIST', response.data)
      })
  },
  async get_customer_target ({ dispatch, commit }, payload) {
    return await this.$axios
      .$get('/selected-customers', {
        params: {
          ids: payload
        }
      })
      .then((response) => {
        commit('TARGET_CUSTOMER_ID', response.data)
      })
  }
}

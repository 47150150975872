export const state = () => ({
  statistics: null,
  promotions: {},
  promotion: {},
  loading: false
})

export const mutations = {
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  },
  PROMOTIONS (state, payload) {
    state.promotions = payload
  },
  SET_PROMOTION (state, payload) {
    state.promotion = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async fetch ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/promotions', {
        params: {
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          search_text: payload.search_text,
          'filter[type]': payload.type,
          'filter[productable]': payload.productable,
          'filter[duration]': payload.duration,
          'filter[created_at]': payload.date_range
        }
      })
      .then((response) => {
        commit('PROMOTIONS', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async addPromotion ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('/promotions', payload)
    commit('SETLOADING', false)
    return response
  },
  async getPromotion ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios.$get(`/promotions/${payload}`).then((response) => {
      commit('SETLOADING', false)
      commit('SET_PROMOTION', response.data)
    })
  },
  async getPromotionWithFilter ({ commit, state }, payload) {
    commit('SETLOADING', true)
    const promotionId = state.promotion.id // Access the promotion ID from the state
    return await this.$axios.$get(`/promotions/${promotionId}`, {
      params: {
        from: payload.from,
        to: payload.to
      }
    }).then((response) => {
      commit('SETLOADING', false)
      commit('SET_PROMOTION', response.data)
    })
  },
  async editPromotion ({ commit }, payload) {
    return await this.$axios.$post(`/promotions/${payload.id}`, payload.form_data)
  },
  async deletePromotion ({ dispatch, commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$delete('/promotions', {
      data: {
        ids: payload
      }
    })
    commit('SETLOADING', false)
    return response
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/promotions').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  }
}

export const state = () => ({
  albums: {},
  statistics: {},
  album: {},
  responseMessage: {
    successMessage: null,
    message: null
  },
  successMessageDelate: {},
  loading: false
})

export const mutations = {
  SETALBUMS (state, payload) {
    state.albums = payload
  },
  GET_ALBUM (state, payload) {
    state.album = payload
  },
  DelateMessage (state, payload) {
    state.successMessageDelate = payload
  },
  RESPONSEMESSAGE (state, payload) {
    state.responseMessage.successMessage = payload.success_message
    state.responseMessage.message = payload.message
  },
  SET_LOADING (state, payload) {
    state.loading = payload
  },
  ADDTAG (state, payload) {
    state.album.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.album.tag_names.splice(payload, 1)
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      const response = await this.$axios.$get(
        '/publications?filter[type]=albums',
        {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[can_comment]': payload.is_purchasable,
            'filter[active]': payload.active,
            'filter[trashed]': payload.trashed
          }
        }
      )
      commit('SETALBUMS', response.data)
      return response
    } else {
      const response = await this.$axios.$get(
        '/publications?filter[type]=albums',
        {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'
          }
        }
      )
      commit('SETALBUMS', response.data)
      return response
    }
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post(
        '/publications?type=albums',
        payload
      )
      commit('SETLOADING', false)
      commit('SETALBUMS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios
      .$get('/statistics/publications?type=albums')
      .then((response) => {
        commit('SETSTATISTICS', response.data)
      })
  },
  async getAlbum ({ commit }, payload) {
    return await this.$axios
      .$get(`/publications/albums/${payload}`)
      .then((response) => {
        commit('GET_ALBUM', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  async AddNewAlbum ({ commit }, payload) {
    const response = await this.$axios.$post('/publications/albums', payload)
    // params: {
    //   question: payload.quetionBody,
    //   answer: payload.answerBody,
    //   is_available: payload.is_available
    // }
    // })
    commit('RESPONSEMESSAGE', payload)
    return response
  },

  async editAlbum ({ commit }, payload) {
    const response = await this.$axios.$post(
      `/publications/albums/${payload.id}`,
      payload.form_data
    )
    commit('RESPONSEMESSAGE', payload)
    return response
  },

  async deleteAlbum ({ commit }, payload) {
    const response = await this.$axios.$delete('/publications/albums', {
      params: {
        ids: payload
      }
    })
    return response
    // commit('DelateMessage', response.message)
  }
}

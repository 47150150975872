export const state = () => ({
  marketerProducts: [],
  marketerPayments: [],
  marketerSales: [],
  marketers: [],
  statistics: [],
  loading: false
})

export const mutations = {
  SETMARKETERS (state, payload) {
    state.marketers = payload
  },
  SETMARKETERPRODUCTS (state, payload) {
    state.marketerProducts = payload
  },
  SETMARKETERSALES (state, payload) {
    state.marketerSales = payload
  },
  SETMARKETERPAYMENTS (state, payload) {
    state.marketerPayments = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}
export const actions = {
  async fetch ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      return await this.$axios
        .$get('/affiliate-marketers', {
          params: {
            search_text: payload.search_text,
            page: payload.page,
            per_page: payload.per_page,
            orderBy: payload.orderBy,
            'filter[role_updated_at]': payload.role_updated_at ? payload.role_updated_at : null,
            'filter[affiliated_sales_unpaid_amount]': payload.affiliated_sales_unpaid_amount ? payload.affiliated_sales_unpaid_amount : null,
            'filter[affiliate_percentage]': payload.affiliate_percentage ? payload.affiliate_percentage : null,
            'filter[affiliate_active]': payload.affiliate_active !== null ? payload.affiliate_active : null

          }
        })
        .then((response) => {
          commit('SETLOADING', false)

          commit('SETMARKETERS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    } else {
      commit('SETLOADING', true)
      return this.$axios
        .$get('/affiliate-marketers', {
          params: {
            search_text: '',
            page: 1,
            per_page: 10,
            orderBy: 'created_at'

          }
        })
        .then((response) => {
          commit('SETLOADING', false)
          commit('SETMARKETERS', response.data)
        })
        .catch(() => {
          commit('SETLOADING', false)
        })
    }
  },
  async getMarketerProducts ({ commit }, payload) {
    commit('SETLOADING', true)
    await this.$axios.$get(`customers/${payload.customer_id}/affiliated-items`, {
      params: {
        search_text: payload.search_text,
        page: payload.page,
        orderBy: payload.orderBy
      }
    }
    ).then((response) => {
      commit('SETMARKETERPRODUCTS', response.data)
    })
    commit('SETLOADING', false)
  },
  async getStatistics ({ commit }, customerId) {
    commit('SETLOADING', true)

    await this.$axios.$get(`statistics/affiliated-payments?customer_id=${customerId}`).then((response) => {
      commit('SETLOADING', false)
      commit('SETSTATISTICS', response.data)
    })
  },
  async getMarketerSales ({ commit }, payload) {
    commit('SETLOADING', true)
    await this.$axios.$get(`customers/${payload.customer_id}/affiliated-sales`, {
      params: {
        search_text: payload.search_text,
        page: payload.page,
        per_page: payload.per_page,
        orderBy: payload.orderBy
      }
    }
    ).then((response) => {
      commit('SETMARKETERSALES', response.data)
    })
    commit('SETLOADING', false)
  },
  addProductToMarketer ({ commit, dispatch }, payload) {
    const response = this.$axios.$post('/affiliated-items', payload)
    return response
  },
  async addProductPayment ({ commit, dispatch }, payload) {
    const response = await this.$axios.$post('/affiliated-payments', payload)
    return response
  },
  async getMarketerPayments ({ commit, dispatch }, payload) {
    commit('SETLOADING', true)

    await this.$axios.$get(`customers/${payload.customer_id}/affiliated-payments`, {
      params: {
        search_text: payload.search_text,
        page: payload.page,
        per_page: payload.per_page,
        orderBy: payload.orderBy
      }
    }
    ).then((response) => {
      commit('SETMARKETERPAYMENTS', response.data)
    })
    commit('SETLOADING', false)
  },
  async getReceivableBond ({ commit }, payload) {
    return await this.$axios.$get(`affiliated-payments/${payload}`)
  },
  exportMarketers ({ _ }, payload) {
    return this.$axios
      .$get('/export-marketers', {
        responseType: 'blob',
        headers: {
          Authorization: this.$axios.defaults.headers.common.Authorization
        }
      })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'marketers.xlsx')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
  },
  exportAffiliatedPayments ({ _ }, payload) {
    return this.$axios
      .$get(`customers/${payload}/export-affiliated-payments`, {
        responseType: 'blob',
        headers: {
          Authorization: this.$axios.defaults.headers.common.Authorization
        }
      })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'MarkterAffiliatePaymentExport.xlsx')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
  },
  exportMarketerSales ({ _ }, payload) {
    return this.$axios
      .$get(`customers/${payload}/export-affiliated-sales`, {
        responseType: 'blob',
        headers: {
          Authorization: this.$axios.defaults.headers.common.Authorization
        }
      })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'MarkterAffiliateSalesExport.xlsx')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
  }
}

export const state = () => ({

})

export const mutations = {}
export const actions = {
  async sendEmail ({ commit }, payload) {
    return await this.$axios
      .$post('/forgot-password', payload).then((response) => {
      })
  },
  async resetPassword ({ commit }, payload) {
    return await this.$axios
      .$post(`/reset-password/${payload.token}`, payload.formData)
  }
}

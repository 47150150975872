export const state = () => ({
  event: null
})

export const mutations = {
  SETEVENT (state, payload) {
    state.event = payload
  }
}
export const actions = {
  async update ({ commit }, payload) {
    const response = await this.$axios.$post('/pages/custom/monthly-events', payload)

    commit('SETEVENT', response.data)
    // commit('RESPONSEMESSAGE', payload)
    return response
  },
  async show ({ commit }) {
    const response = await this.$axios.$get('/pages/custom/monthly-events')

    commit('SETEVENT', response.data)
    return response
  }
}

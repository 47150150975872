export const state = () => ({
  ebooks: {},
  ebook: {},
  statistics: {},
  summary: '',
  loading: false
})

export const mutations = {
  EBOOKS (state, payload) {
    state.ebooks = payload
  },
  SETEEBOOK (state, payload) {
    state.ebook = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  summaryName (state, payload) {
    state.summary = payload
  },
  ADDTAG (state, payload) {
    state.ebook.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.ebook.tag_names.splice(payload, 1)
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}

export const actions = {
  fetch ({ commit }, payload) {
    commit('SETLOADING', true)
    return this.$axios
      .$get('/ebooks', {
        params: {
          search_text: payload.search_text,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          'filter[is_purchasable]': payload.is_purchasable,
          'filter[active]': payload.active,
          'filter[trashed]': payload.trashed

        }
      })
      .then((response) => {
        commit('SETLOADING', false)
        commit('EBOOKS', response.data)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/ebooks', payload)
      commit('SETLOADING', false)
      commit('EBOOKS', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/ebooks').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async getEbooks ({ commit }, payload) {
    return await this.$axios.$get(`/ebooks/${payload}`).then((response) => {
      commit('SETEEBOOK', response.data)
    })
  },
  addEBook ({ commit }, payload) {
    const response = this.$axios.$post('/ebooks', payload)
    return response
  },
  async ediEBook ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post(`/ebooks/${payload.id}`, payload.form_data)
    commit('SETLOADING', false)
    return response
  },
  async deletEBook ({ commit }, payload) {
    const response = await this.$axios.$delete('/ebooks', {
      params: {
        ids: payload
      }
    })
    return response
  }
}

export const state = () => ({
  bundles: {},
  bundle: {},
  productList: [],
  statistics: {},
  loading: false
})

export const mutations = {
  BUNDLES (state, payload) {
    state.bundles = payload
  },
  PRODUCTLIST (state, payload) {
    state.productList = payload
  },
  SETBUNDLE (state, payload) {
    state.bundle = payload
  },
  SETLOADING (state, payload) {
    state.loading = payload
  },
  ADDTAG (state, payload) {
    state.bundle.tag_names.push(payload)
  },
  DELETETAG (state, payload) {
    state.bundle.tag_names.splice(payload, 1)
  },
  SETSTATISTICS (state, payload) {
    state.statistics = payload
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('SETLOADING', true)
    return await this.$axios
      .$get('/bundles', {
        params: {
          search_text: payload.search_text,
          page: payload.page,
          per_page: payload.per_page,
          orderBy: payload.orderBy,
          'filter[is_purchasable]': payload.is_purchasable,
          'filter[active]': payload.active,
          'filter[trashed]': payload.trashed
        }
      })
      .then((response) => {
        commit('BUNDLES', response.data)
        commit('SETLOADING', false)
      })
      .catch((error) => {
        commit('SETLOADING', false)
        console.log(error)
      })
  },
  async filter ({ commit }, payload) {
    if (payload !== null && payload !== undefined) {
      commit('SETLOADING', true)
      const response = await this.$axios.$post('/bundles', payload)
      commit('SETLOADING', false)
      commit('BUNDLES', response.data)
      return response
    }
  },
  async getStatistics ({ commit }) {
    await this.$axios.$get('/statistics/bundles').then((response) => {
      commit('SETSTATISTICS', response.data)
    })
  },
  async fetch_products ({ commit }, payload) {
    return await this.$axios.$get('bundles/products').then((response) => {
      commit('PRODUCTLIST', response.data)
    })
  },

  async getBundle ({ commit, dispatch }, payload) {
    return await this.$axios.$get(`/bundles/${payload}`).then((response) => {
      commit('SETBUNDLE', response.data)
    })
  },
  async addBundle ({ commit }, payload) {
    const response = await this.$axios.$post('/bundles', payload)
    return response
  },
  async editBundle ({ commit }, payload) {
    commit('SETLOADING', true)

    const response = await this.$axios.$post(
      `/bundles/${payload.id}`,
      payload.form_data
    )
    commit('SETLOADING', true)
    return response
  },
  async bundleDuplicate ({ commit }, payload) {
    const response = await this.$axios.$post('/bundles/duplicate', payload)
    return response
  },
  bulkChanges ({ commit }, payload) {
    return this.$axios.$post('/bundles', payload)
  },
  async deleteBundle ({ commit }, payload) {
    const response = await this.$axios.$delete('/bundles', {
      data: {
        ids: payload
      }
    })
    return response
  },
  async confirmDeletion ({ commit }, payload) {
    return await this.$axios.$delete('/bundles', {
      data: {
        ids: payload,
        confirm_deletion: true
      }
    })
  },
  async applyProcedure ({ commit }, payload) {
    commit('SETLOADING', true)
    const response = await this.$axios.$post('bundles', payload.form_data)

    // commit('RESPONSEMESSAGE', payload)
    return response
  }
}
